(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/dialog-contextual/assets/javascripts/dialog-contextual.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/dialog-contextual/assets/javascripts/dialog-contextual.js');
"use strict";


const {
  ReactIcon,
  ReactIconButton
} = svs.ui;
const {
  Contextual,
  TEXT
} = svs.components.dialog;
const DialogContextual = _ref => {
  let {
    dialog
  } = _ref;
  const showDialog = event => {
    svs.components.dialog.api.add(new Contextual({
      area: svs.components.dialog.area.CONTEXTUAL,
      branding: svs.components.dialog.branding.SPORT,
      attachTo: event.currentTarget,
      title: '',
      message: [{
        type: TEXT,
        text: dialog
      }]
    }));
  };
  return React.createElement(ReactIconButton, {
    onClick: e => showDialog(e)
  }, React.createElement(ReactIcon, {
    color: "icon-inverted",
    icon: "help"
  }));
};
setGlobal('svs.poker_react.components.DialogContextual', DialogContextual);

 })(window);